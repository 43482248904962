import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import {Helmet} from "react-helmet";
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ArtistPageTemplate = ({ data: { post } }) => {
  const featuredImage = {
    //data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    //alt: post.featuredImage?.node?.alt || ``,
  }
  return (
    <Layout>
    <Helmet>
        <script src={'/menu.js'} />
        <link rel="icon" href={'/ico32.jpg'} sizes="32x32" />
        <link rel="icon" href={'/ico192.jpg'} sizes="192x192" />
        <link rel="apple-touch-icon" href={'/ico180.jpg'} />
        <meta name="msapplication-TileImage" content={'/ico270.jpg'} />
    </Helmet>
      <article
        className="global-wrapper blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >

      <div class="jumbotron p-4 p-md-5 text-black rounded bg-light">
        <div class="col-md-6 px-0">
          <h1 class="display-4 font-italic">Hama Beads {parse(post.title)}</h1>
          <p class="lead my-4">Learn how to do a Hama Beads {parse(post.title)} step by step</p>
          <p class="lead mb-3">&nbsp;</p>
        </div>
      </div>
          <h2 className="mt-4">Free Download {post.title} Hama Beads</h2>
          <div>
            <img className="hamabeads" title={`${post.title} Hama Beads Template`} src={`/imgs/${post.slug}-hama-beads.jpg`} alt={`${post.title} Hama Beads Template`} />
          </div>
        {!!post.text && (
          <section itemProp="articleBody">
          <h2 className="mt-4">{post.title} Hama Beads template Step by Step</h2>
            <div>{parse(post.text)}</div>
          </section>
        )}
        <section>
          
        </section>
      </article>
    </Layout>
  )
}

export default ArtistPageTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
  ) {
    post: mysqlHamabeadsEn(id: { eq: $id }) {
      id
      slug
      title
      text
      metatitle
      metatext
    }
  }
`
